<template>
    <div class="comData" :style="{paddingTop: landingPagePaddingTop + 'px', paddingBottom: landingPagePaddingBot + 'px'}">
        <el-form style="margin-top: 20px" :model="ruleForm" :rules="rules" ref="ruleForm">
            <div v-for="(item, index) in pageComponentData" :key="index" :index="index">
                <component
                    v-if="item.component != 'v-text'"
                    :is="item.component"
                    :style="item.style"
                    :id="'component' + item.id"
                    class="component"
                    :class="item.style.isDown === 0? 'down-btn': ''"
                    :prop-value="item.propValue"
                    :element="item"
                    :ruleForm="ruleForm"
                    :handlerClick="hanlderBtn"
                />
                <component
                    :is="item.component"
                    v-else
                    :id="'component' + item.id"
                    class="component"
                    :class="item.style.isDown === 0? 'down-btn': ''"
                    :style="getComponentStyle(item.style)"
                    :prop-value="item.propValue"
                    :element="item"
                    :ruleForm="ruleForm"
                    :handlerClick="hanlderBtn"
                />
            </div>
        </el-form>
    </div>
</template>
 
<script>

import formItem from '../../minxins/form-item';
export default {
    name: 'flow-page',
    mixins: [formItem],
    components: {
        // 注册组件
  
    },
    data() {
        return {
            rules: {},
            ruleForm: {},
            landingPagePaddingBot: 0,
            landingPagePaddingTop: 0,
        }
    },
    computed: {
        pageComponentData() {
            return this.$store.state.flowPage.messagePageComponentData;
        }
    },
    watch: {
      pageComponentData: {
        handler(newVal) {
          this.$nextTick(() => {
             let elTH = 0, elBH = 0;
              newVal.map(item => {
                if (item.style.isDown === 0) {//组件打开了吸底
                  elBH = document.getElementById('component' + item.id).clientHeight;//获取当前组件高度
                } else if (item.style.isUp === 0) {//组件打开了吸顶
                  elTH = document.getElementById('component' + item.id).clientHeight;//获取当前组件高度
                }
              }, 0)
              // 画布顶部、底部内边距
              this.landingPagePaddingTop = elTH
              this.landingPagePaddingBot = elBH
          })
        },
        immediate: true,
        deep: true,
      },
    },
    mounted() {
        this.setFormValidator(this.pageComponentData);
        this.$store.commit('setPayType', 'ALI')
    },
    methods: {
        // 按钮点击事件
        hanlderBtn(btnType) {
            if (btnType === 'v-button') {//普通按钮
                this.submitBtn(this.pageComponentData);
            } else if (btnType === 'v-buttonPay' || btnType === 'v-pay') {
                this.submitBtn(this.pageComponentData);
            } else if (btnType === 'v-PhoneText') {//手机验证码，先收集用户数据
                this.submitFormData(this.pageComponentData);
            }
        },
        // 预览模式跳转
        navigationTo() {
            this.$router.push('tip-page');
        },
    }
}
</script>
 
<style lang="scss" scoped>
@import url('../../styles/form-item.scss');
</style>